<div class="relative">
  <section class="header fixed top-0 left-0 right-0 backdrop-opacity-40 px-4 flex  items-center">
    <div class="flex w-full">
      <div class="flex flex-row w-full">
        <div class="navbar-nav pl-2 flex w-5/6">
          <img src="../assets/images/logo.svg" class="mr-a"/>
          <div class="navbar-nav menu">
            <ul class="flex">
              <li class="mr-a cursor-pointer"><a (click)="clickMenu('search')" [routerLink]="['/auction-search']" >Buscar imóvel</a></li>
              <li class="mr-a cursor-pointer"><a (click)="clickMenu('faq')">FAQ</a></li>
              <li class="mr-a cursor-pointer"><a (click)="clickMenu('plans')">Planos</a></li>
              <li class="mr-a cursor-pointer"><a (click)="clickMenu('business')">Institucional</a></li>
              <li class="mr-a cursor-pointer"><a (click)="clickMenu('blog')">Blog</a></li>
              <li class="mr-a cursor-pointer"><a (click)="clickMenu('contact')">Contato</a></li>
              <li class="mr-a cursor-pointer"><a (click)="clickMenu('fake')">Simule um investimento</a></li>
            </ul>
          </div> 
        </div>
        <div class="flex justify-end w-1/6">
          <ul class="flex pl-4 navbar-nav ">
            <li class="mr-a cursor-pointer"><a [routerLink]="['/auth/sign-up']">Cadastre-se</a></li>
            <li class="mr-3 cursor-pointer"><a [routerLink]="['/auth/sign-in']" class="btn text-white">Login</a></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  
  <div class="flex relative">
    <div style="width: 65%;height: 100%;z-index: -1; inset: 0 0 0 0;" class="fixed">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="1983" viewBox="0 0 669 1983" fill="none"><g filter="url(#filter0_b_888_12341)"><path d="M-412.359 1964C-391.766 1964 -325.66 1970.08 -314.037 1948.95C-302.413 1927.82 -308.478 1938.83 -308.478 1938.83L668.641 352.006L468.392 -20.2507L452.473 -49.3197C440.976 -70.4489 421.646 -83 401.052 -83H236.81L460.306 354.438L-528.689 1982.5H-447.136H-406.359H-365.583L-412.359 1964Z" fill="url(#paint0_linear_888_12341)"></path></g><defs><filter id="filter0_b_888_12341" x="-532.688" y="-87" width="1205.33" height="2073.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feGaussianBlur in="BackgroundImageFix" stdDeviation="2"></feGaussianBlur><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_888_12341"></feComposite><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_888_12341" result="shape"></feBlend></filter><linearGradient id="paint0_linear_888_12341" x1="541.312" y1="912" x2="284.95" y2="-240.197" gradientUnits="userSpaceOnUse"><stop stop-color="#E4ED2E"></stop><stop offset="0.956268" stop-color="#FDFFD9" stop-opacity="0"></stop></linearGradient></defs></svg>
    </div>
    <div style="top: 0px; right: 0px; width: 50%; height: 100%; z-index: -1;" class="fixed">
      <svg fill="none" viewBox="0 0 631 2066" height="2066" width="631" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_b_888_12519)"><path d="M1081.02 2047.5C1060.43 2047.5 994.34 2053.58 982.72 2032.44C971.1 2011.31 977.162 2022.32 977.162 2022.32L0.3125 435.111L200.506 62.7645L216.421 33.6885C227.915 12.5541 247.24 0 267.827 0H432.024L208.59 437.544L1197.31 2066H1115.78H1075.02H1034.25L1081.02 2047.5Z" fill="url(#paint0_linear_888_12519)"></path></g><defs><filter id="filter0_b_888_12519" x="-3.6875" y="-4" width="1205" height="2074" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feGaussianBlur in="BackgroundImageFix" stdDeviation="2"></feGaussianBlur><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_888_12519"></feComposite><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_888_12519" result="shape"></feBlend></filter><linearGradient id="paint0_linear_888_12519" x1="97.3125" y1="389.5" x2="668.02" y2="1447.97" gradientUnits="userSpaceOnUse"><stop stop-color="#E4ED2E" stop-opacity="0.6"></stop><stop offset="0.956268" stop-color="#FDFFD9" stop-opacity="0"></stop></linearGradient></defs></svg>
    </div>
  </div>
  <router-outlet></router-outlet>
  <!-- <footer></footer> -->
</div>