import { Routes } from '@angular/router';
import { AuctionSearchComponent } from './modules/auction-search/containers/auction-search/auction-search.component';
import { AuthGuard } from './core/auth/guards/auth.guard';

export const routes: Routes = [
    {
		path: 'auction-search',
		component: AuctionSearchComponent,
		loadChildren: () => import('../app/modules/auction-search/auction-search.module').then((m) => m.AuctionSearchModule),
		title: 'Sharx - Busca avançada',
	},
	{
		path: 'auth',
		loadChildren: () => import('../app/modules/sign-up/sign-up.module').then((m) => m.SignUpModule),
	},
	{
		path: 'restricted-area',
		loadChildren: () => import('../app/modules/advertising/clients-advertising.module').then((m) => m.ClientsAdvertisingModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'payments',
		loadChildren: () => import('../app/modules/payments/payments.module').then((m) => m.PaymentsModule),
	},
	{ path: '', redirectTo: 'auction-search', pathMatch: 'full' },
	{ path: '**', redirectTo: 'auction-search' },
];
