import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import Swal from 'sweetalert2';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	/**
	 * Constructor
	 */
	constructor(private _authService: AuthService) {}

	/**
	 * Intercept
	 *
	 * @param req
	 * @param next
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Clone the request object
		let newReq = req.clone();

		// Response
		return next.handle(newReq).pipe(
			catchError((error) => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === 401) {
						//this._authService.signOut();
						location.reload();
					}

					if (error.status === 403) {
						//this._customToasterService.showErrorMessage('unauthorized');
					}

					if (error.status === 500) {
						// this._customToasterService.showErrorMessage('internal-server-error');
					}

					if (error.status === 0) {
						// this._customToasterService.showErrorMessage(error.statusText, false);
					}

					// if (error.status === 400) {
						
					// }

					if (Array.isArray(error.error)) {
						error.error.forEach((erro: Mensagem) => {
							Swal.fire({
								title: 'Erro!',
								text: erro.texto,
								icon: 'error',
								confirmButtonText: 'Ok'
							  })

						});
					} else {
						// this._customToasterService.showErrorMessage(error.error.error_description);
					}
				}

				return throwError(error);
			})
		);
	}
}

export class Mensagem {
	key: string;
	texto: string;
	titulo: string;
}