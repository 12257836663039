import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'sharx-portal-angular';

  dictionaryMenu: Map<string, string> = new Map([
    ["search", "busca-avancada"],
    ["faq", "faq"],
    ["plans", "planos"],
    ["business", "institucional"],
    ["blog", "blog"],
    ["contact", "fale-conosco"],
    ["fake", "simule-um-investimento"],

  ]);

  clickMenu(menu:string) {
    var url = "https://sharxleiloes.com"
    window.open(`${url}/${this.dictionaryMenu.get(menu)}`, '_blank')!.focus();
  }
}
