export class AppRoutes {

  static readonly AuctionSearch = {
    Root: {
      path: 'profile',
      text: 'Meus Dados'
    },
    MyRegistrationData: {
      path: 'my-registration-data',
      completePath: '/profile/my-registration-data',
      text: 'Dados Cadastrais',
      title: 'Dados Cadastrais'
    }
  };

  static readonly Auth = {
    Root: {
      path: 'auth/sign-in',
      text: 'Login'
    },
  };

}
